$(document).ready(function () {
  const MODAL_SETTINGS = {
    fadeDuration: 350,
    showSpinner: false,
  };

  function support_format_webp()
  {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d')))
    {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    else
    {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }
  const supportsWebp = support_format_webp()

  if(supportsWebp) {
    $('html').addClass('webp')
  } else {
    $('html').addClass('no-webp')
  }

  $('.slider--item').find($('.slider--item-title')).hover(function(){
    $('.slider--item.slider--item-center').find($('.slider--item-image')).toggleClass('hover');
  });

  $("#openModal").click((e) =>{
    $("#openModal").css('z-index', '0');
    $(e.currentTarget).css('opacity', 0);
  });
  $("#close").click(() => {
    $("#openModal").css('z-index', '0')
    $("#openModal").css('opacity', '1')
  }
  )
  // DOMMouseScroll included for firefox support
  var canScroll = true,
    scrollController = null;
  $(this).on('mousewheel DOMMouseScroll', function (e) {

    if (!($('.outer-nav').hasClass('is-vis'))) {

      var delta = (e.originalEvent.wheelDelta) ? -e.originalEvent.wheelDelta : e.originalEvent.detail * 20;

      if (delta > 50 && canScroll) {
        canScroll = false;
        clearTimeout(scrollController);
        scrollController = setTimeout(function () {
          canScroll = true;
        }, 800);
        updateHelper(1);
      }
      else if (delta < -50 && canScroll) {
        canScroll = false;
        clearTimeout(scrollController);
        scrollController = setTimeout(function () {
          canScroll = true;
        }, 800);
        updateHelper(-1);
      }

    }

  });

  $('a.open-modal').click(function (event) {
    $(this).modal(MODAL_SETTINGS);
    return false;
  });

  $("#contactForm").submit(function (e) {
    e.preventDefault();
    const submitBtn = $(this).find("input[type='submit']");
    submitBtn.prop('disabled', true);
    const checkboxes = $('input:checkbox');
    let optionChosen = false;
    checkboxes.each(function (i) { this['checked'] ? optionChosen = true : null })
    if (!optionChosen) {
      $('#form-open-error-option-modal').click()
      submitBtn.prop('disabled', false);
      return;
    }
    const name = $('#name')
    const email = $('#email')
    if (!name.val().trim() || !email.val()) {
      $('#form-open-error-name-modal').click()
      submitBtn.prop('disabled', false);
      return;
    }
    const action = $(this).attr("action");
    $('.hire').hide();
    $('.loader').show()
    $.ajax({
      type: "POST",
      url: '/',
      crossDomain: true,
      data: new URLSearchParams(new FormData(this)).toString(),
      dataType: "html",
      contentType: "application/x-www-form-urlencoded",
      processData: false,
      headers: {
        "Accept": "application/json"
      }
    }).done(function () {
      $('.loader').hide();
      $('.hire').show();
      $.get('thanks.html', function (html) {
        $(html).appendTo('body').modal(MODAL_SETTINGS);
      });
      checkboxes.removeAttr('checked');
      checkboxes.each(function (i) {
        this.checked = false;
      })
      name.val('').removeClass('has-value');
      email.val('').removeClass('has-value');
      submitBtn.prop('disabled', false);
    }).fail(function () {
      $('.loader').hide();
      $('.hire').show();
      $.get('error.html', function (html) {
        $(html).appendTo('body').modal(MODAL_SETTINGS);
      }).then(() => {
        $('.modal').attr('style', 'display: block; top: 20px; right: 20px; position: absolute');
        setTimeout(() => {
          $('.modal').attr('style', '');
        }, 2000)
      });
      checkboxes.removeAttr('checked');
      name.val('').removeClass('has-value');
      email.val('').removeClass('has-value');
      submitBtn.prop('disabled', false);
    });
  });

  $('.header--logo').click(() => {
    updateNavs(0);
    updateContent(1, 0, 0);
  })

  $('.side-nav li, .outer-nav li').click(function () {

    if (!($(this).hasClass('is-active'))) {

      var $this = $(this),
        curActive = $this.parent().find('.is-active'),
        curPos = $this.parent().children().index(curActive),
        nextPos = $this.parent().children().index($this),
        lastItem = $(this).parent().children().length - 1;

      updateNavs(nextPos);
      updateContent(curPos, nextPos, lastItem);

    }

  });

  $('.cta').click(function () {

    var curActive = $('.side-nav').find('.is-active'),
      curPos = $('.side-nav').children().index(curActive),
      lastItem = $('.side-nav').children().length - 1,
      nextPos = lastItem;

    updateNavs(lastItem);
    updateContent(curPos, nextPos, lastItem);

  });

  // swipe support for touch devices
  var targetElement = document.getElementById('viewport'),
    mc = new Hammer(targetElement);
  mc.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
  mc.on('swipeup swipedown', function (e) {

    updateHelper(e);

  });

  $(document).keyup(function (e) {

    if (!($('.outer-nav').hasClass('is-vis'))) {
      e.preventDefault();
      updateHelper(e);
    }

  });

  // determine scroll, swipe, and arrow key direction
  function updateHelper(param) {

    var curActive = $('.side-nav').find('.is-active'),
      curPos = $('.side-nav').children().index(curActive),
      lastItem = $('.side-nav').children().length - 1,
      nextPos = 0;

    if (param.type === "swipeup" || param.keyCode === 40 || param > 0) {
      if (curPos !== lastItem) {
        nextPos = curPos + 1;
        updateNavs(nextPos);
        updateContent(curPos, nextPos, lastItem);
      }
      else {
        updateNavs(nextPos);
        updateContent(curPos, nextPos, lastItem);
      }
    }
    else if (param.type === "swipedown" || param.keyCode === 38 || param < 0) {
      if (curPos !== 0) {
        nextPos = curPos - 1;
        updateNavs(nextPos);
        updateContent(curPos, nextPos, lastItem);
      }
      else {
        nextPos = lastItem;
        updateNavs(nextPos);
        updateContent(curPos, nextPos, lastItem);
      }
    }

  }

  // sync side and outer navigations
  function updateNavs(nextPos) {

    $('.side-nav, .outer-nav').children().removeClass('is-active');
    $('.side-nav').children().eq(nextPos).addClass('is-active');
    $('.outer-nav').children().eq(nextPos).addClass('is-active');

  }

  // update main content area
  function updateContent(curPos, nextPos, lastItem) {

    $('.main-content').children().removeClass('section--is-active');
    $('.main-content').children().eq(nextPos).addClass('section--is-active');
    $('.main-content .section').children().removeClass('section--next section--prev');

    if (curPos === lastItem && nextPos === 0 || curPos === 0 && nextPos === lastItem) {
      $('.main-content .section').children().removeClass('section--next section--prev');
    }
    else if (curPos < nextPos) {
      $('.main-content').children().eq(curPos).children().addClass('section--next');
    }
    else {
      $('.main-content').children().eq(curPos).children().addClass('section--prev');
    }

    if (nextPos !== 0 && nextPos !== lastItem) {
      $('.header--cta').addClass('is-active');
    }
    else {
      $('.header--cta').removeClass('is-active');
    }

  }

  function outerNav() {

    $('.header--nav-toggle').click(function () {

      $('.perspective').addClass('perspective--modalview');
      setTimeout(function () {
        $('.perspective').addClass('effect-rotate-left--animate');
      }, 25);
      $('.outer-nav, .outer-nav li, .outer-nav--return').addClass('is-vis');

    });

    $('.outer-nav--return, .outer-nav li').click(function () {

      $('.perspective').removeClass('effect-rotate-left--animate');
      setTimeout(function () {
        $('.perspective').removeClass('perspective--modalview');
      }, 400);
      $('.outer-nav, .outer-nav li, .outer-nav--return').removeClass('is-vis');

    });

  }

  function workSlider() {

    let isAnimating = false;

    $('.slider--prev, .slider--next').click(function () {

      if (isAnimating) return;
      isAnimating = true;

      var $this = $(this),
          slider = $('.slider'),
          curLeft = slider.find('.slider--item-left'),
          curLeftPos = slider.children().index(curLeft),
          curCenter = slider.find('.slider--item-center'),
          curCenterPos = slider.children().index(curCenter),
          curRight = slider.find('.slider--item-right'),
          curRightPos = slider.children().index(curRight),
          totalWorks = slider.children().length,
          $left = $('.slider--item-left'),
          $center = $('.slider--item-center'),
          $right = $('.slider--item-right'),
          $item = $('.slider--item');

      slider
          .queue(function () {
            $(this).css({ 'opacity': 0, 'pointer-events': 'none' });
            $(this).dequeue();
          })
          .delay(300)
          .queue(function () {
            if ($this.hasClass('slider--next')) {
              if (curLeftPos < totalWorks - 1 && curCenterPos < totalWorks - 1 && curRightPos < totalWorks - 1) {
                $left.removeClass('slider--item-left').next().addClass('slider--item-left');
                $center.removeClass('slider--item-center').next().addClass('slider--item-center');
                $right.removeClass('slider--item-right').next().addClass('slider--item-right');
              } else {
                if (curLeftPos === totalWorks - 1) {
                  $item.removeClass('slider--item-left').first().addClass('slider--item-left');
                  $center.removeClass('slider--item-center').next().addClass('slider--item-center');
                  $right.removeClass('slider--item-right').next().addClass('slider--item-right');
                } else if (curCenterPos === totalWorks - 1) {
                  $left.removeClass('slider--item-left').next().addClass('slider--item-left');
                  $item.removeClass('slider--item-center').first().addClass('slider--item-center');
                  $right.removeClass('slider--item-right').next().addClass('slider--item-right');
                } else {
                  $left.removeClass('slider--item-left').next().addClass('slider--item-left');
                  $center.removeClass('slider--item-center').next().addClass('slider--item-center');
                  $item.removeClass('slider--item-right').first().addClass('slider--item-right');
                }
              }
            } else {
              if (curLeftPos !== 0 && curCenterPos !== 0 && curRightPos !== 0) {
                $left.removeClass('slider--item-left').prev().addClass('slider--item-left');
                $center.removeClass('slider--item-center').prev().addClass('slider--item-center');
                $right.removeClass('slider--item-right').prev().addClass('slider--item-right');
              } else {
                if (curLeftPos === 0) {
                  $item.removeClass('slider--item-left').last().addClass('slider--item-left');
                  $center.removeClass('slider--item-center').prev().addClass('slider--item-center');
                  $right.removeClass('slider--item-right').prev().addClass('slider--item-right');
                } else if (curCenterPos === 0) {
                  $left.removeClass('slider--item-left').prev().addClass('slider--item-left');
                  $item.removeClass('slider--item-center').last().addClass('slider--item-center');
                  $right.removeClass('slider--item-right').prev().addClass('slider--item-right');
                } else {
                  $left.removeClass('slider--item-left').prev().addClass('slider--item-left');
                  $center.removeClass('slider--item-center').prev().addClass('slider--item-center');
                  $item.removeClass('slider--item-right').last().addClass('slider--item-right');
                }
              }
            }
            $(this).dequeue();
          })
          .delay(12)
          .queue(function () {
            $(this).css({ 'opacity': 1 });
            $(this).dequeue();
          })
          .delay(350)
          .queue(function () {
            isAnimating = false;
            $(this).css({ 'pointer-events': 'auto' });
            $(this).dequeue();
          });
    });
  }

  function transitionLabels() {

    $('.work-request--information input').focusout(function () {

      var textVal = $(this).val();

      if (textVal === "") {
        $(this).removeClass('has-value');
      }
      else {
        $(this).addClass('has-value');
      }

      // correct mobile device window position
      window.scrollTo(0, 0);

    });

  }

  outerNav();
  workSlider();
  transitionLabels();

});
